<template>
    <div id="home">
        <div class="videoPart">
            <div class="logo"><img :src="require('../assets/logo.png')" alt=""></div>
            <video :src="require('../assets/video.mp4')" autoplay muted loop></video>
        </div>
        <div class="contacts">
            <div class="elements">
                <div class="contactsElement">

                    <div class="icon"><img :src="require('../assets/icons/phone.svg')" alt=""></div>
                    <a href="tel:+393498149480"><div class="text">+39 349 8149480</div></a>
                </div>

                <div class="contactsElement">
                    <div class="icon"><img :src="require('../assets/icons/email.svg')" alt=""></div>
                    <a href="mailto:star3nic@icloud.com"><div class="text">star3nic@icloud.com</div></a>
                </div>

                <div class="contactsElement">
                    <div class="icon"><img :src="require('../assets/icons/location.svg')" alt=""></div>
                   <a href="https://www.google.it/maps/place/Corte+Batanelli,+27,+55011+Altopascio+LU/@43.8240347,10.6538548,18.56z/data=!4m5!3m4!1s0x132a78d2904a6cd7:0x45263991cdc09204!8m2!3d43.824045!4d10.654308">
                     <div class="text">Località Batanelli 27 <br> Altopascio (LU)</div>
                   </a>
                </div>

            </div>
        </div>
        <div class="content">
            <div class="chiSiamo">
                <div class="left">
                    <img :src="require('../assets/chiSiamo.jpg')" alt="">
                </div>
                <div class="right">
                    <h3>PAVIMENTAZIONI STAR3</h3>
                    <p>La ditta pavimentazioni industriali STAR3 opera nel settore delle pavimentazioni in calcestruzzo ad uso industriale e civile avvalendosi di personale specializzato con decennale esperienza nel settore.</p>
                </div>
            </div>

            <div class="servizi">
                
                    <div class="servizio" :style="'background-image: url(\''+require('../assets/imgs/categorie/pavInterne.jpg')+'\')'">
                        <router-link to="/servizi">
                            <div class="contenuto"></div>
                            <div class="txt">PAVIMENTAZIONI INTERNE</div>
                        </router-link>
                    </div>
                
                <div class="servizio" :style="'background-image: url(\''+require('../assets/imgs/categorie/pavEsterne.jpg')+'\')'">
                    <router-link to="/servizi">
                        <div class="txt">PAVIMENTAZIONI ESTERNE</div>
                    </router-link>
                </div>
                <div class="servizio" :style="'background-image: url(\''+require('../assets/imgs/categorie/sassoLavato.jpg')+'\')'">
                    <router-link to="/servizi">
                        <div class="txt">SASSO LAVATO</div>
                    </router-link>
                </div>
                <div class="servizio" :style="'background-image: url(\''+require('../assets/imgs/categorie/antipolvere.jpg')+'\')'">
                    <router-link to="/servizi">
                        <div class="txt">TRATTAMENTI ANTIPOLVERE</div>
                    </router-link>
                </div>
            </div>

        </div>
        <GMap/>
    </div>
</template>

<script>
import GMap from '../components/GMap.vue'
export default {
    components:{GMap}
}
</script>

<style scoped>
.videoPart{
    width: 100%;
    height: 100vh;
    position: relative;
}
.logo{
    position: absolute;
    top:50%;left:50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    backdrop-filter: blur(3px);
    padding: 20px;
    border-radius: 10px;
    max-width: 100%;
    width: max-content;
}
.logo > img{
    max-width: 100%;
}
video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contacts{
    width: 100%;
    background: rgb(250,250,250);
    background: linear-gradient(153deg, rgba(250,250,250,1) 0%, rgba(249,249,249,1) 100%);
    color: rgb(27, 27, 27);
}
.contacts > .elements{
    display: flex;
    justify-content: space-around;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 0px;
}
@media screen and (max-width: 555px) {
    .contacts > .elements{
        flex-flow: column;
        row-gap: 10px;
        padding: 20px 10px 20px 10px;
    }
}
.contactsElement{
    text-align: center;
    transition-duration: 0.2s;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
}
a{
    color: unset;
    text-decoration: none;
}
.contactsElement >  .icon{
    width: 50px;
    margin: 0 auto;
}
.contactsElement:hover{
    background-color: rgb(88, 84, 52);
    color: rgb(250,250,250);
    transition-duration: 0.2s;
}
.contactsElement:hover >  .icon >img{
    filter: invert(92%) sepia(0%) saturate(7366%) hue-rotate(143deg) brightness(111%) contrast(96%);
    transition-duration: 0.1s;
}
.chiSiamo{
    max-width: 1200px;
    display: flex;
    background: rgb(250,250,250);
    background: linear-gradient(153deg, rgba(250,250,250,1) 0%, rgba(249,249,249,1) 100%);
    margin: 50px auto 0px auto;
    border-radius: 4px;
    overflow: hidden;
}
.chiSiamo > .left{
    flex:1;
}
.chiSiamo > .left > img{
    width: 100%;
}
.chiSiamo > .right{
    flex:1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.chiSiamo > .right > h3{
    font-size:  25pt;
}
.chiSiamo > .right > p{
    text-align: justify;
    text-justify: inter-word;
    text-align: center;
    margin-top: 10px;
    padding: 0px 20px;
}

@media screen and (max-width: 800px) {
    .chiSiamo{
        flex-flow: column;
    }
}
.servizi{
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 930px;
    row-gap: 5px;
    column-gap: 5px;
    padding: 10px 10px;
    background: rgb(250,250,250);
    background: linear-gradient(153deg, rgba(250,250,250,1) 0%, rgba(249,249,249,1) 100%);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.servizi > .servizio{
    color: white;
    flex: 1 0 auto;
    width: 100%;
    max-width: 450px;
    height:auto;
    background-size: cover;
    background-position: center center;
    border-radius: 5px;
    transition-duration: 0.2s;
}
.servizi > .servizio:hover{
    -webkit-box-shadow: 5px 5px 10px 0px #B5B5B5;
    -moz-box-shadow: 5px 5px 10px 0px #B5B5B5;
    -o-box-shadow: 5px 5px 10px 0px #B5B5B5;
    box-shadow: 5px 5px 10px 0px #B5B5B5;
    transition-duration: 0.2s;
}
.servizi > .servizio:before {
    content:'';
    float:left;
    padding-top:100%;
}

.servizi > .servizio > a > .txt{
    background-color: rgba(46, 46, 46, 0.219);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 30pt;
    text-align: center;
    border-radius: 5px;
    text-shadow: rgba(37, 37, 37, 0.658) 2px 5px;
    padding: 10px 10px;
}
@media screen and (max-width: 406px) {
    .servizi > .servizio > a > .txt{
        font-size: 20pt;
    }
}
@media screen and (max-width: 290px) {
    .servizi > .servizio > a > .txt{
        font-size: 15pt;
    }
}
@media screen and (max-width: 225px) {
    .servizi > .servizio > a > .txt{
        font-size: 12pt;
    }
}

.mt{
    margin-top: 10px;
}
</style>