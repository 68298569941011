import Vue from 'vue'
import VueRouter from 'vue-router'


import Home from './views/Home.vue'
import Servizi from './views/Servizi.vue'
import Contatti from './views/Contatti.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Home', component: Home},
    { path: '/servizi', name: 'Home', component: Servizi},
    { path: '/contatti', name: 'Home', component: Contatti},
]

const router = new VueRouter({
    scrollBehavior(to) {
      if (to.hash) {return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });}
      return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    mode: 'history',
    routes,
  })
  
  export default router