<template>
    <div id="contatti">
        <div class="halfImg">
            <div class="img" :style="'background-image:url(\''+require('../assets/contatti.jpg')+'\')'"></div>
            <div class="blur"></div>
            <div class="title">CONTATTI</div>
        </div>
        <GMap/>
    </div>
</template>

<script>
import GMap from '../components/GMap.vue'
export default {
    components:{GMap}
}
</script>

<style scoped>
.halfImg{
    width: 100%;
    height: 50vh;
    background-color: rgb(226, 226, 226);
    position: relative;
}
.halfImg > .img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 40%;
}
.halfImg > .blur{
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    z-index: 3;
}
.halfImg > .title{
    z-index: 4;
    position: absolute;
    top:50%;left:50%;
    transform: translateX(-50%) translateY(-50%);
    width: max-content;
    font-size: 60pt;
    color: white;
    font-weight: 700;
}
@media screen and (max-width: 480px) {
    .halfImg > .title{
        font-size: 40pt;
    }
}
@media screen and (max-width: 315px) {
    .halfImg > .title{
        font-size: 30pt;
    }
}
@media screen and (max-width: 240px) {
    .halfImg > .title{
        font-size: 20pt;
    }
}
</style>