<template>
    <div id="menu">
        <div class="hamburger" @click="showNav=true"><span>MENU</span></div>
        <div :class="showNav==true?'menu opened':'menu'">
            <div class="close" @click="showNav=false">X</div>
            <div class="content">
                <div class="elements">
                    <router-link to="/"><div class="element">HOME</div></router-link>
                    <router-link to="/servizi"><div class="element">SERVIZI</div></router-link>
                    <router-link to="/contatti"><div class="element">CONTATTI</div></router-link>
                </div>
                <div class="icons">
                    <a href=""><div class="icon"><img :src="require('../assets/fb.svg')" alt=""></div></a>
                    <a href="https://www.instagram.com/pavimentazionistar3/"><div class="icon"><img :src="require('../assets/ig.svg')" alt=""></div></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){return{
        showNav: false,
    }},
    watch:{
        $route (){
            this.showNav = false;
        }
    } 
}
</script>

<style scoped>
.hamburger{
    position:fixed;
    top:10px;right:10px;
    border: 2px solid black;
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.226);
    backdrop-filter: blur(2px);
    cursor: pointer;
    z-index: 50;
    transition-duration: 0.2s;
    width: 70px;
    height: 30px;
}
.hamburger > span{
    position:absolute;
}
.hamburger:hover{
    background: black;
    transition-duration: 0.2s;
    color: white;
}
.menu{
    width: 100%;
    position: fixed;
    height: 100vh;
    background: rgb(250,250,250);
    background: linear-gradient(153deg, rgba(250,250,250,1) 0%, rgba(249,249,249,1) 100%);
    z-index: 60;
    top:-100%;
    transition-duration: 0.2s;
}
.opened{
    top: 0px;
    transition-duration: 0.5s;
}
.menu > .close{
    position: absolute;
    top:15px;right:15px;
    border: 2px solid black;
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
}

.menu > .close:hover{
    background: black;
    transition-duration: 0.2s;
    color: white;
}
.content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.content > .elements{
    display: flex;
    align-content: center;
    flex-flow: column;
    text-align: center;
}
.content > .elements > a > .element{
    font-size: 25pt;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 0px 20px;
}
.content > .elements > a > .element:hover{
    background-color: black;
    color: white;
    cursor: pointer;
}
a{
    text-decoration: none;
    color: unset;
}
.icons{
    display: flex;
    column-gap: 5px;
}
.icons > a > .icon{
    width: 50px;
    height: 50px;
    padding: 4px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}
.icons > a > .icon:hover{
    background: #000;
}
.icons > a > .icon:hover > img{
    filter: invert(100%) sepia(25%) saturate(594%) hue-rotate(230deg) brightness(111%) contrast(101%);
}
.icons > a > .icon > img{
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;top:50%;
    transform: translateX(-50%) translateY(-50%);
}
</style>