<template>
    <div id="servizi">
        <div class="halfImg">
            <div class="img" :style="'background-image:url(\''+require('../assets/servizi.jpg')+'\')'"></div>
            <div class="blur"></div>
            <div class="title">SERVIZI.</div>
        </div>
        <div class="servizi">
            <div class="servizio">
                <div class="left">
                    <div class="slideShow">
                        <vueper-slides :dragging-distance="30" fixed-height="400px" :bullets="false" v-if="slidesPavInterneDone">
                            <vueper-slide v-for="(slide, i) in slidesPavInterne" :key="i"  :image="slide.image" />
                        </vueper-slides>
                    </div>
                </div>
                <div class="right">
                    <h3>PAVIMENTAZIONI INTERNE</h3>
                    <p>Le caratteristiche principali dei pavimenti industriali in cemento sono la resistenza, la durata e la compattezza. </p>
                    <p>Tali proprietà rendono questo tipo di pavimentazioni adatte ad ogni tipo di ambiente. </p>
                    <p>Il materiale che fornisce queste caratteristiche è il quarzo. Lo spessore varia da 5cm(pedonale) a un minimo di 10cm(carrabile).</p>
                    <p class="mt">La nostra società offre tutti gli strumenti per mantenere la pavimentazione nel tempo.</p>
                    <p>Su richiesta è possibile eseguire un trattamento impregnante. </p>
                </div>
            </div>
            <div class="servizio">
                <div class="left">
                    <div class="slideShow">
                        <vueper-slides :dragging-distance="30" fixed-height="400px" :bullets="false" v-if="slidesPavEsterneDone">
                            <vueper-slide v-for="(slide, i) in slidesPavEsterne" :key="i"  :image="slide.image" />
                        </vueper-slides>
                    </div>
                </div>
                <div class="right">
                    <h3>PAVIMENTAZIONI ESTERNE</h3>
                    <p>Il pavimento in calcestruzzo a finitura "mille righe" o "pettinata" sono una lavorazione artigianale che conferisce un effetto unico.</p>
                    <p>Nasce dall'esigenza di rendere la superficie antiscivolo.</p>
                    <p>Questa finitura si adatta alle rampe d'accesso ai garage, parcheggi e altri accessi carrabili.</p>
                    <p class="mt">La nostra società offre tutti gli strumenti per mantenere la pavimentazione nel tempo.</p>
                    <p>Su richiesta è possibile eseguire un trattamento impregnante. </p>
                </div>
            </div>
            <div class="servizio">
                <div class="left">
                    <div class="slideShow">
                        <vueper-slides :dragging-distance="30" fixed-height="400px" :bullets="false" v-if="slidesSassoLavatoDone">
                            <vueper-slide v-for="(slide, i) in slidesSassoLavato" :key="i"  :image="slide.image" />
                        </vueper-slides>
                    </div>
                </div>
                <div class="right">
                    <h3>SASSO LAVATO</h3>
                    <p>Le pavimentazioni in "sasso lavato" sono un'alternativa efficace per la loro durata e i costi sostenibili.</p>
                    <p>La resa paesaggistica è perfetta in quanto pienamente integrata con l'ambiente circostante.</p>
                    <p>Limita e facilita notevolmente l'impegno nei riguardi della pulizia, migliorandone l'aspetto e assicurandone l'effetto frenante.</p>
                </div>
            </div>
            <div class="servizio">
                <div class="left">
                    <div class="slideShow">
                        <vueper-slides :dragging-distance="30" fixed-height="400px" :bullets="false" v-if="slidesTrattAntipolvereDone">
                            <vueper-slide v-for="(slide, i) in slidesTrattAntipolvere" :key="i"  :image="slide.image" />
                        </vueper-slides>
                    </div>
                </div>
                <div class="right">
                    <h3>TRATTAMENTI ANTIPOLVERE</h3>
                    <p>Rivestimento antipolvere, anti sporco, trasparente. La pavimentazione viene lavorata in modo artigianalmente con lo scopo di realizzare tonalità di fondo chiare e con sfumature superficiali. La pavimentazione ha un aspetto volutamente disomogeneo, creato con l'impegno di particolari prodotti. Rende la superficie resistente ai graffi e facilmente lavabile.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
    components:{VueperSlides,VueperSlide},
    data() {
        return {
            slidesPavInterne:[],
            slidesPavEsterne:[],
            slidesSassoLavato:[],
            slidesTrattAntipolvere:[],
            slidesPavInterneDone:false,
            slidesPavEsterneDone:false,
            slidesSassoLavatoDone:false,
            slidesTrattAntipolvereDone:false,
        }
    },
    mounted(){
        var x = 1;
        while(x<=26){
            this.slidesPavInterne.push({image: require('@/assets/imgs/pavInterne/'+x+'.jpg')})
            x++;
        }
        this.slidesPavInterneDone = true;
        x = 1;
        while(x<=62){
            this.slidesPavEsterne.push({image: require('@/assets/imgs/pavEsterne/'+x+'.jpg')})
            x++;
        }
        this.slidesPavEsterneDone = true;
        x = 1;
        while(x<=31){
            this.slidesSassoLavato.push({image: require('@/assets/imgs/sassoLavato/'+x+'.jpg')})
            x++;
        }
        this.slidesSassoLavatoDone = true;
        x = 1;
        while(x<=9){
            this.slidesTrattAntipolvere.push({image: require('@/assets/imgs/trattamentiAntipolvere/'+x+'.jpg')})
            x++;
        }
        this.slidesTrattAntipolvereDone = true;
    }
}
</script>

<style scoped>
.halfImg{
    width: 100%;
    height: 50vh;
    background-color: rgb(226, 226, 226);
    position: relative;
}
.halfImg > .img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 40%;
}
.halfImg > .blur{
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    z-index: 3;
}
.halfImg > .title{
    z-index: 4;
    position: absolute;
    top:50%;left:50%;
    transform: translateX(-50%) translateY(-50%);
    width: max-content;
    font-size: 60pt;
    color: white;
    font-weight: 700;
}
@media screen and (max-width: 370px) {
    .halfImg > .title{
        font-size: 40pt;
    }
}
@media screen and (max-width: 255px) {
    .halfImg > .title{
        font-size: 30pt;
    }
}@media screen and (max-width: 190px) {
    .halfImg > .title{
        font-size: 20pt;
    }
}

.servizio{
    max-width: 800px;
    display: flex;
    background: rgb(250,250,250);
    background: linear-gradient(153deg, rgba(250,250,250,1) 0%, rgba(249,249,249,1) 100%);
    margin: 50px auto 0px auto;
    border-radius: 4px;
    overflow: hidden;
}

@media screen and (max-width: 750px) {
    .servizio{
        flex-flow: column;
    }
}

.servizio > .left{
    flex:1;
}
.servizio > .left > img{
    width: 100%;
}
.servizio > .right{
    flex:1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    text-align: center;
}
.servizi{
    margin-bottom: 50px;
}
.mt{
    margin-top: 10px;
}
</style>