<template>
  <div id="app">
    <Menu />
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
export default {
  components:{Menu,Footer},
  name: 'App',
}
</script>

<style>

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }
  #app{

  }

</style>
